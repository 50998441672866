import cn from 'classnames';
import { useRouter } from 'next/router';
import Script from 'next/script';

export default function FbChatPlugin() {
  const { pathname } = useRouter();

  return (
    <div
      className={cn({
        hidden: !(
          pathname.includes('/recruitment') ||
          pathname.includes('/job') ||
          pathname.includes('/tuyen-dung')
        ),
      })}
    >
      <div id="fb-root"></div>
      <div id="fb-customer-chat" className="fb-customerchat"></div>
      <Script strategy="lazyOnload" id="fb-customer-chat-script">
        {`
            var chatbox = document.getElementById('fb-customer-chat');
            chatbox.setAttribute("page_id", "1895190027399039");
            chatbox.setAttribute("attribution", "biz_inbox");

            window.fbAsyncInit = function() {
              FB.init({
                xfbml            : true,
                version          : 'v18.0'
              });
            };

            (function(d, s, id) {
              var js, fjs = d.getElementsByTagName(s)[0];
              if (d.getElementById(id)) return;
              js = d.createElement(s); js.id = id;
              js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
              fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        `}
      </Script>
    </div>
  );
}
