import { Hydrate } from '@tanstack/react-query';
import cn from 'classnames';
import type { NextPage } from 'next';
import { appWithTranslation } from 'next-i18next';
import type { AppProps as NextAppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import type { ReactElement, ReactNode } from 'react';
import { Suspense, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import FbChatPlugin from '@/components/FbChatPlugin';
import { gilroy } from '@/lib/fonts';
import nextI18nextConfig from '../../next-i18next.config';
import { AppProviders } from '../AppProviders';
import '../styles/global.css';

export type NextPageWithLayout<P = NonNullable<unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = NextAppProps & {
  /** Will be defined only is there was an error */
  err?: Error;
  Component: NextPageWithLayout;
};

function App(props: AppPropsWithLayout) {
  const router = useRouter();
  const {
    Component,
    pageProps: { dehydratedState, isSsrMobile, ...pageProps },
    err,
  } = props;

  NProgress.configure({ showSpinner: false });

  useEffect(() => {
    router.events.on('routeChangeStart', () => NProgress.start());
    router.events.on('routeChangeComplete', () => NProgress.done());
    router.events.on('routeChangeError', () => NProgress.done());
  }, [router.events]);

  return (
    <AppProviders isSsrMobile={isSsrMobile}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=5,viewport-fit=cover"
        />
      </Head>
      {/* Hydrate query cache */}
      <Hydrate state={dehydratedState}>
        <div
          className={cn(
            gilroy.variable,
            'font-gilroy text-neutral-90 dark:bg-neutral-90 flex min-h-screen flex-col bg-white dark:text-white'
          )}
        >
          <Component {...pageProps} err={err} key={router.asPath} />
          <ToastContainer autoClose={3000} hideProgressBar={true} />
        </div>
      </Hydrate>
      <Suspense fallback={null}>
        <FbChatPlugin />
      </Suspense>
    </AppProviders>
  );
}

export default appWithTranslation(App, {
  ...nextI18nextConfig,
});
